import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const EnquiryForm = Loadable(lazy(() => import('views/pages/authentication/authentication3/EnquiryForm')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthResetPwd = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
const AuthForgotPwd = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword')));
const HomePage = Loadable(lazy(() => import('views/pages/home-page/HomePage')));
const AboutPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/AboutUs')));
const ContactPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/ContactUs')));
const Products_ServicesPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/Products_Services')));
const Privacy_PolicyPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/PrivacyPolicy')));
const DisclaimerPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/Disclaimer')));
const Terms_ConditionsPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/Terms_Conditions')));
const Refund_Return_PolicyPage = Loadable(lazy(() => import('views/pages/home-page/sub-pages/RefundReturnPolicy')));
const Student_Subscription_PageInfo = Loadable(lazy(() => import('views/pages/home-page/sub-pages/StudentSubscriptionInfo')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />,
            children: [
                {
                    path: '/',
                    element: <AboutPage />
                },
                {
                    path: '/about-us',
                    element: <AboutPage />
                },
                {
                    path: '/contact-us',
                    element: <ContactPage />
                },
                {
                    path: '/products-services',
                    element: <Products_ServicesPage />
                },
                {
                    path: '/privacy-policy',
                    element: <Privacy_PolicyPage />
                },
                {
                    path: '/refund-return-policy',
                    element: <Refund_Return_PolicyPage />
                },
                {
                    path: '/terms-conditions',
                    element: <Terms_ConditionsPage />
                },
                {
                    path: '/student-subscription-info',
                    element: <Student_Subscription_PageInfo />
                },
                {
                    path: '/disclaimer',
                    element: <DisclaimerPage />
                }
            ]
        },
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/enquiry-form',
            element: <EnquiryForm />
        },
        {
            path: '/reset-password/:token',
            element: <AuthResetPwd />
        },
        {
            path: '/goto-reset-screen',
            element: <AuthForgotPwd />
        }
        // {
        //     path: '/register',
        //     element: <AuthRegister3 />
        // }
    ]
};

export default AuthenticationRoutes;

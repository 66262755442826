const envVariables = {
    //*******************AWS configuration***********************/
    // awsBucket: 'nabhdlm', // production
    awsBucket: 'nabhdlm-testing', // testing
    signatureVersion: 'v4',
    accessKeyId: 'AKIAYZUO6EY3CGJTPFTP',
    secretAccessKey: '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',
    region: 'us-east-2',
    //*******************AWS configuration***********************/
    //************Remember to Put '/' at end of api**************/
    baseURL: 'https://nabh-dlm-api-prod.nabhtech.com/',
    geminiURL: 'https://gemini-qna-api-42ctymu3wq-as.a.run.app/',
    awsBucketUrl: 'https://prod-dlm.s3.ap-south-1.amazonaws.com/',
    awsBucketFolder: 'testNabhdlm',
    awsBucketTestFolder: 'ltst_prod_dlm_test/',
    awsBucketQuestionFolder: 'ltst_prod_dlm_questions/'
};

export default envVariables;

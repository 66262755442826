// assets
import { IconBrandChrome, IconHelp, IconBooks } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconBooks };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const classes = {
    id: 'student',
    title: 'Student',
    type: 'group',
    children: [
        {
            id: 'practice',
            title: 'Practice',
            type: 'item',
            url: '/practice',
            icon: icons.IconBooks,
            breadcrumbs: false
            // children: [
            //     {
            //         id: 'class-first',
            //         title: 'Class 1st',
            //         type: 'item',
            //         url: '/classfirst',
            //         icon: icons.IconBooks,
            //         breadcrumbs: false
            //     },
            // ]
        },
        {
            id: 'test-screen',
            title: 'Test',
            type: 'item',
            url: '/test-screen',
            icon: icons.IconBooks,
            breadcrumbs: false
        }
    ]
};

export default classes;

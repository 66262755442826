import React from 'react';
import { useImperativeHandle, forwardRef } from 'react';
import propTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { prototype } from 'apexcharts';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbars = forwardRef(({ snackbarprops }, ref) => {
    const [open, setOpen] = React.useState(false);
    useImperativeHandle(ref, () => ({
        showSnackbar() {
            setOpen(true);
        }
    }));
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackbarprops.color} sx={{ width: '100%' }}>
                    {snackbarprops.content}
                </Alert>
            </Snackbar>
        </div>
    );
});
Snackbars.propTypes = {
    snackbarprops: propTypes.object
};
export default Snackbars;

import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
import teacher from './teacher';
import classes from './classes';
import admin from './admin';
// Redux
// import { store } from 'store/index';
// ==============================|| MENU ITEMS ||============================== //

const user = JSON.parse(localStorage.getItem('loggedUser'));
// const storeData = store.getState();
// console.log(storeData);
if (user != null) {
    if (user.role == 'teacher') {
        var menuItems = {
            items: [dashboard, teacher]
        };
    }
    if (user.role == 'admin') {
        var menuItems = {
            items: [dashboard, admin, teacher, classes]
        };
    }
    if (user.role == 'student') {
        var menuItems = {
            items: [dashboard, classes]
        };
    }
}
if (user == null) {
    var menuItems = {
        items: [dashboard, classes]
    };
}

export default menuItems;

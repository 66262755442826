import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAdminTeacher = Loadable(lazy(() => import('views/dashboard-admin-teacher/Default')));
const DashboardStudent = Loadable(lazy(() => import('views/dashboard-student/Default')));
const HomePage = Loadable(lazy(() => import('views/pages/home-page/HomePage')));
// create & view question page routing
const CreateQuestion = Loadable(lazy(() => import('views/create-question')));
const ViewQuestions = Loadable(lazy(() => import('views/view-questions-list')));
const UploadQuestions = Loadable(lazy(() => import('views/upload-bulk-questions')));
// Practice question page routing
const PracticeQuestion = Loadable(lazy(() => import('views/question-page/Questions')));
// Test question page routing
const TestQuestions = Loadable(lazy(() => import('views/student-test-screen/test-screen')));
// Registeration page routing
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthRegisterStudent3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/RegisterStudent3')));
// Registered Student List page routing
const RegisteredStaffList = Loadable(lazy(() => import('views/staff-list/index')));
// Registered Student List page routing
const RegisterStudentList = Loadable(lazy(() => import('views/register-student-list/index')));
const EnquiryList = Loadable(lazy(() => import('views/enquiry-list/index')));
// Subscription page routing
const StudentSubscriptionPage = Loadable(lazy(() => import('views/pages/student-subscription-page/StudentSubscriptionPage')));
const StudentSubscriptionSuccess = Loadable(lazy(() => import('views/pages/student-subscription-success/StudentSubscriptionSuccess')));
const StudentSubscriptionNotActivate = Loadable(
    lazy(() => import('views/pages/student-subscription-not-activate/StudentSubscriptionNotActivate'))
);
const StudentSubscriptionFail = Loadable(lazy(() => import('views/pages/student-subscription-fail/StudentSubscriptionFail')));
// Test Teacher page routing
const CreateTest = Loadable(lazy(() => import('views/other-forms-pages/layout/CreateTest')));
const ViewTestDetails = Loadable(lazy(() => import('views/teacher-view-tests/index')));
const StudentsTestRecords = Loadable(lazy(() => import('views/teacher-students-test-records/index')));
// Student Report Card
const StudentReportCard = Loadable(lazy(() => import('views/student-report-card/Default')));

// ==============================|| MAIN ROUTING ||============================== //
const user = JSON.parse(localStorage.getItem('loggedUser'));
const role = user == null ? 'demo-user' : user.role;
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // {
        //     path: '/',
        //     element:
        //         role === 'student' ? (
        //             <DashboardStudent />
        //         ) : role === 'admin' || role === 'teacher' ? (
        //             <DashboardDefault />
        //         ) : (
        //             <DashboardDefault />
        //         )
        // },
        // {
        //     path: '/',
        //     element: <HomePage />
        // },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element:
                        role === 'student' ? (
                            <DashboardStudent />
                        ) : role === 'admin' || role === 'teacher' ? (
                            <DashboardAdminTeacher />
                        ) : (
                            <DashboardDefault />
                        )
                }
            ]
        },
        // {
        //     path: '/student-dashboard',
        //     element: role === 'student' ? <DashboardStudent /> : <DashboardDefault />
        // },
        {
            path: '/register-staff',
            element: role === 'admin' ? <AuthRegister3 /> : <DashboardDefault />
        },
        {
            path: '/register-student',
            element: role === 'admin' ? <AuthRegisterStudent3 /> : <DashboardDefault />
        },
        {
            path: '/registered-staff-list',
            element: role === 'admin' ? <RegisteredStaffList /> : null
        },
        {
            path: '/register-student-list',
            element: role === 'admin' ? <RegisterStudentList /> : null
        },
        {
            path: '/enquiry-list',
            element: role === 'admin' ? <EnquiryList /> : null
        },
        // need to work on it
        // {
        //     path: '/student-report-card',
        //     element: role === 'admin' ? <StudentReportCard /> : null
        // },
        {
            path: 'create-question',
            element: role === 'admin' || role === 'teacher' ? <CreateQuestion /> : <DashboardDefault />
        },
        {
            path: 'create-test',
            element: role === 'admin' || role === 'teacher' ? <CreateTest /> : <DashboardDefault />
        },
        {
            path: 'view-questions',
            element: role === 'admin' || role === 'teacher' ? <ViewQuestions /> : <DashboardDefault />
        },
        {
            path: 'upload-bulk-questions',
            element: role === 'admin' || role === 'teacher' ? <UploadQuestions /> : <DashboardDefault />
        },
        {
            path: 'view-tests-details',
            element: role === 'admin' || role === 'teacher' ? <ViewTestDetails /> : <DashboardDefault />
        },
        {
            path: 'students-test-records',
            element: role === 'admin' || role === 'teacher' ? <StudentsTestRecords /> : <DashboardDefault />
        },
        {
            path: 'test-screen',
            element: role === 'admin' || role === 'teacher' || role === 'student' ? <TestQuestions /> : <DashboardDefault />
        },
        {
            path: 'practice',
            element: role === 'admin' || role === 'teacher' || role === 'student' ? <PracticeQuestion /> : <DashboardDefault />
        },
        {
            path: 'student-subscription',
            element: role === 'student' ? <StudentSubscriptionPage /> : null
        },
        {
            path: 'subscription-activated',
            element: role === 'student' ? <StudentSubscriptionSuccess /> : null
        },
        {
            path: 'subscription-not-activated',
            element: role === 'student' ? <StudentSubscriptionNotActivate /> : null
        },
        {
            path: 'transaction-failed',
            element: role === 'student' ? <StudentSubscriptionFail /> : null
        }
    ]
};

export default MainRoutes;

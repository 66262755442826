const user = JSON.parse(localStorage.getItem('loggedUser'));

if (sessionStorage.getItem('token')) {
    var AuthHeader = {
        headers: {
            token: `${sessionStorage.getItem('token')}`,
            role: user != null ? (user.role == 'admin' || user.role == 'teacher' ? 'staff' : 'student') : null
        }
    };
} else {
    var AuthHeader = {
        headers: null
    };
}

export default AuthHeader;

// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    children: [
        {
            id: 'register-staff',
            title: 'Register Staff',
            type: 'item',
            url: '/register-staff',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'registered-staff-list',
            title: 'Staff List',
            type: 'item',
            url: '/registered-staff-list',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'register-student',
            title: 'Register Student',
            type: 'item',
            url: '/register-student',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'register-student-list',
            title: 'Student List',
            type: 'item',
            url: '/register-student-list',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'enquiry-list',
            title: 'Enquiry List',
            type: 'item',
            url: '/enquiry-list',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
        // Need to work on it
        // ,
        // {
        //     id: 'student-report-card',
        //     title: 'Report Card',
        //     type: 'item',
        //     url: '/student-report-card',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false
        // }
    ]
};

export default admin;

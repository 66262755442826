import { useState, useEffect } from 'react';
import moment from 'moment';
// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginTop: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    }
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = () => {
    // const [UserRole, setUserRole] = useState('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const userPlan = {
    //     planType: '',
    //     subscriptionEndDate: '',
    //     subscriptionStartDate: '',
    //     subscriptionStatus: '',
    //     role: 'demo user'
    // };
    // const [UserPlanData, setUserPlanData] = useState(userPlan);
    const [UserPlanData, setUserPlanData] = useState(
        JSON.parse(localStorage.getItem('loggedUser')) != null
            ? JSON.parse(localStorage.getItem('loggedUser')).hasOwnProperty('className') == true
                ? [JSON.parse(localStorage.getItem('loggedUser'))]
                : []
            : []
    );
    useEffect(() => {
        JSON.parse(localStorage.getItem('loggedUser')) != null
            ? JSON.parse(localStorage.getItem('loggedUser')).hasOwnProperty('className') == true
                ? setUserPlanData([JSON.parse(localStorage.getItem('loggedUser'))])
                : setUserPlanData([])
            : setUserPlanData([]);
    }, []);
    return (
        <>
            {UserPlanData.length > 0 ? (
                UserPlanData[0].role == 'student' ? (
                    <CardStyle>
                        <CardContent>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="h4">{UserPlanData[0].planType.toUpperCase()}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                                        {UserPlanData[0].planType == 'free'
                                            ? 'Subscribe any plan to get all features'
                                            : 'Congratulation! You can use all features.'}
                                    </Typography>
                                </Grid>
                                {UserPlanData[0].planType != 'free' ? (
                                    <Grid item>
                                        <Grid container direction="row" justifyContent="space-bewteen" alignItems="center">
                                            <Grid item xs={6} md={6}>
                                                <Typography variant="h6" color="primary">
                                                    Purchased on
                                                </Typography>
                                                <Typography variant="subtitle2" color="primary" sx={{ opacity: 0.6 }}>
                                                    {moment(UserPlanData[0].subscriptionStartDate).format('DD-MM-YYYY')}
                                                </Typography>
                                            </Grid>
                                            <Grid item item xs={6} md={6}>
                                                <Typography variant="h6" color="error">
                                                    Expires on
                                                </Typography>
                                                <Typography variant="subtitle2" color="error" sx={{ opacity: 0.6 }}>
                                                    {moment(UserPlanData[0].subscriptionEndDate).format('DD-MM-YYYY')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </CardContent>
                    </CardStyle>
                ) : null
            ) : null}
        </>
    );
};

export default UpgradePlanCard;
